@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .flight-plus-hotel {
    border: 16px solid var(--c_turquoise_secondary);

    .icon {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      border: 2px solid var(--c_cosmos);
      border-radius: 50%;

      &::before {
        color: var(--c_cosmos);
      }
    }

    .t-h3 {
      color: var(--c_cosmos);
    }

    .t-label {
      color: var(--c_concrete);
    }
  }
}
