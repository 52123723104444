@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.offers-index-list {
  .js-tabs {
    margin-bottom: 0;
  }

  .js-tabs-content {
    padding: 0;
    background: none transparent;

    > div {
      &:last-child {
        .horizontal-card {
          margin-bottom: 0;
        }
      }
    }
  }

  #offers-drop-down {
    display: none;
  }
}

.offers-index-header {
  margin-bottom: 20px;

  .wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .block-title {
    flex-grow: 1;
    margin-bottom: 5px;

    a {
      display: inline-block;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .actions {
    position: relative;
    align-self: flex-end;
    padding-bottom: 10px;
    white-space: nowrap;

    // @include paragraph-small(var(--font-sans));
    .l-down {
      margin-left: 5px;

      &.active {
        @include icon(arrow-up);
      }
    }

    .drop-down-links {
      top: calc(100% - 5px);
      left: auto;
      right: 0;

      &::before {
        left: 80%;
      }
    }
  }
}

.offer-dates-legend {
  @include fontSize(16);
  display: inline-block;
  flex: 1 1 auto;
  margin-bottom: 15px;
  text-align: right;

  &.hidden {
    display: none;
  }

  .basic-tooltip & {
    margin-bottom: 0;
    text-align: left;
  }
}
