@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.holidaycheck-widget {
  position: relative;
  z-index: 10;
  cursor: pointer;

  div {
    z-index: 0;

    a {
      cursor: default;
      pointer-events: none;

      /* IE - disable hover effects */
      &:hover {
        cursor: default;
        color: darkgrey;
        text-decoration: none;
      }
    }
  }
}

.tripadvisor-widget {
  .tripadvisor-open-fancy {
    @include fontSize(14);
    position: relative;
    display: block;
    color: var(--c_primary);
    font-weight: 700;
    line-height: 20px;
  }
}

@include desktopStyles {
  .horizontal-card {
    .holidaycheck-widget,
    .tripadvisor-widget {
      margin-bottom: 6px;
    }

    .tripadvisor-widget {
      img {
        margin-bottom: -4px;
        margin-right: 5px;
      }

      .tripadvisor-open-fancy {
        display: inline-block;
      }
    }
  }

  .large-screens {
    .modal__container {
      max-width: 1200px;
    }
  }
}

@include mobileStyles {
  .card-container {
    .opinion-widget {
      .tripadvisor-widget {
        .widget {
          display: flex;
          margin-bottom: 10px;
        }
      }
    }
  }
}
