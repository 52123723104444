@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.external-url-modal {
  &__title {
    line-height: 36px;
  }

  .secondary-btn {
    margin-right: 10px;
    border: 1px solid var(--c_blue_700);
    color: var(--c_blue_700);

    &:hover:not(.disabled, :disabled),
    &:focus:not(.disabled, :disabled)  {
      background: var(--c_blue_700);
      color: var(--c_white)
    }
  }
}
