@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.modal-popup-fia {
  .popup-fia {
    display: flex;

    &__body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 0 $space3;
    }

    &__content {
      flex: 1;
      height: 100%;
    }
  }
}

@include desktopStyles {
  .modal-popup-fia {
    max-width: 1000px;
  }
}

@include commonTabletMobile {
  .modal-popup-fia {
    .title {
      margin-bottom: $space3;
    }
  }
}
