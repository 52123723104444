@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.social-login {
  @include circle-separator;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0;
  margin-top: 24px;

  li {
    flex: 0 0 100%;
    max-width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  .s {
    margin-right: 5px;

    &.google {
      width: 20px;
      height: 20px;
      background: url('../../../images/icons/ico_google.svg') no-repeat 0 center;
    }
  }
}

.circle-separator,
%circle-separator {
  border-top: 1px solid var(--c_grey_200);

  &::before {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 26px;
    height: 1px;
    background: var(--c_white);
  }

  &::after {
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 7px;
    height: 7px;
    border: 1px solid var(--c_grey_400);
    border-radius: 50%;
  }
}

.circle-separator-vertical {
  @include circle-separator;
  border-top: 0;
  border-left: 1px solid var(--c_grey_200);

  &::before {
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    width: 1px;
    height: 26px;
  }

  &::after {
    top: 50%;
    left: -4px;
    transform: translateY(-50%);
  }
}
