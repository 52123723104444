@use '../../base_css/abstracts/abstracts' as *;

#floating-fastbooking {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  padding: 10px 0;
  border-top: 3px solid var(--c_primary);
  background: rgba(236, 236, 236, 95%);

  .fastbooking {
    position: relative;
    top: 0;
    left: 0;

    .best-rate-link {
      color: var(--c_primary);
      text-shadow: 0 0 0 transparent;
    }

    .fastbooking-links li a {
      color: var(--c_primary);
    }
  }
}
