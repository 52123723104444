@use 'social_login_commons' as *;

.social-login {
  @include circle-separator;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0;
  margin-top: 24px;

  li {
    flex: 0 0 100%;
    max-width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  .s {
    margin-right: 5px;

    &.google {
      width: 20px;
      height: 20px;
      background: url('../../../images/icons/ico_google.svg') no-repeat 0 center;
    }
  }
}
