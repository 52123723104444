@use 'flight_plus_hotel_commons' as *;

$DESKTOP_MAX_WIDTH: 1205px;

@include desktopStyles {
  .flight-plus-hotel {
    max-width: $DESKTOP_MAX_WIDTH;

    // do not override m-* class
    margin-left: auto;
    margin-right: auto;

    .wrapper {
      display: flex;
      padding: 18px 24px 20px 50px;
    }

    .icon {
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
      margin-right: 50px;

      &::before {
        @include fontSize(40);
      }
    }

    .content {
      width: 50%;
    }

    .t-p {
      @include fontSize(14);
    }

    .btn-primary {
      align-self: flex-end;
      margin-left: auto;
    }

    // we are having problems with plane icon generation.
    // centers the plane inside the circle.
    &.plane {
      .icon::after {
        height: 30px;
      }
    }
  }
}
